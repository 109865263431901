import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Bauen = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Bauen – Ein Überblick" showCalc={false} />
            <Article>
                <p>
                    Ein eigenes Haus zu bauen, ist für viele Menschen ein Lebenstraum. Die Verwirklichung birgt
                    allerdings einige Tücken. Worauf du achten musst, damit dein Lebenstraum kein lebenslanger Traum
                    bleibt, erklären wir dir im folgenden Beitrag.
                </p>
                <hr />

                <h2>Welche Kostenanteile muss ich beim Hausbau berücksichtigen?</h2>
                <h3>Kosten für Baustelle und Konstruktion</h3>
                <p>
                    Zunächst ist das der Kaufpreis für das Grundstück als solches sowie die{" "}
                    <Link to="/artikel/kaufnebenkosten/" target="_blank" rel="noreferrer noopener">
                        Nebenkosten
                    </Link>{" "}
                    , die bei dessen Erwerb entstehen. Beide Kostenpunkte variieren in Abhängigkeit von der Lage.
                    Darüber hinaus fallen Kosten für die Herstellung, also die eigentliche Konstruktion deines neuen
                    Hauses an. Darunter fallen die Baumaterialien, die Einrichtung der Baustelle, Erdarbeiten, Roh- und
                    Innenausbau, Dachdeckung, der Ausbau des Kellers sowie die Installation von Außenanlagen. Auch hier
                    solltest du den Überblick über die Nebenkosten behalten: die Honorare für Bauleitung und
                    Architekt*in, Kosten für die Baubewilligung, die Erschließung und Vermessung des Grundstücks sowie
                    für das Bodengutachten. Auch die Bauversicherung ist Teil dieser Nebenkosten.
                </p>
                <p>
                    Einen detaillierten Überblick über sämtliche der genannten Kostenpunkte findest du{" "}
                    <Link to="/artikel/hausbau-kosten/" target="_blank" rel="noreferrer noopener">
                        hier
                    </Link>
                    .
                </p>
                <h3>Finanzierungskosten</h3>
                <p>
                    Auch der{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredit
                    </Link>
                    , den du für die Realisierung deines Wohntraums aufnimmst, bringt Finanzierungskosten mit sich.
                    Damit hier keine unnötigen Gebühren entstehen, solltest du unbedingt die Angebote von möglichst
                    vielen Kreditinstituten vergleichen. Wir übernehmen das gerne für dich. Nachdem wird die Offerte von
                    über 60 Banken verglichen haben, legen wir dir ein Angebot vor, das perfekt auf deine individuelle
                    Situation abgestimmt ist.
                </p>
                <hr />

                <h2>Wie viel Eigenkapital benötige ich beim Bau eines Hauses?</h2>
                <p>
                    Die Frage nach der Summe an Eigenkapital, die bei der Beantragung einer Immobilienkredits benötigt
                    wird, sorgt bei vielen Konsument*innen für große Unsicherheit. “Eigenkapital” meint die Summe an
                    Erspartem, die du selbst in die Finanzierung einbringst, und umfasst
                </p>
                <ul>
                    <li>Sparbücher und -briefe</li>
                    <li>Tages- und Festgeldkonten</li>
                    <li>Aktien und Zinspapiere</li>
                    <li>Gold und Silber</li>
                    <li>Fonds bzw. Anteile an diesen</li>
                    <li>Rückkaufswerte von Lebensversicherungen</li>
                    <li>Bausparguthaben</li>
                    <li>bebaubare Grundstücke sowie Baumaterialien</li>
                    <li>Etwaige Darlehen von Verwandten oder Arbeitgeber*innen</li>
                </ul>
                <p>
                    Üblicherweise erwarten Banken, dass du 20 % (idealerweise sogar 30 %) an Eigenkapital zur
                    Finanzierung deines Bauprojekts beisteuern kannst. Sofern du über ein entsprechend hohes konstantes
                    Einkommen verfügst, ist allerdings auch eine Finanzierung mit weniger oder ganz ohne Eigenkapital
                    nicht ausgeschlossen. Unsere Finanzierungsexpert*innen geben dir gerne Tipps zur Verbesserung deiner{" "}
                    <Link to="/artikel/bonitaet/" target="_blank" rel="noreferrer noopener">
                        Bonität
                    </Link>
                    !
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Zum Kreditvergleich!
                    </a>
                </p>
                <hr />

                <h2>Die Haushaltsrechnung oder: Wie viel Haus kann ich mir leisten?</h2>
                <p>
                    Verschaffe dir zunächst einen Überblick über deine Finanzen und bestimme jenen Teil deines
                    Einkommens, über den du frei verfügen kannst. Mit diesem wirst du später die monatlichen Raten
                    deines{" "}
                    <Link to="/artikel/immobilienfinanzierung/" target="_blank" rel="noreferrer noopener">
                        Immobilienkredits
                    </Link>{" "}
                    bestreiten. Erstelle hierfür am besten eine Haushaltsrechnung. Das hast du noch nie gemacht? Kein
                    Problem! Wir erklären dir Schritt für Schritt, wie du dabei vorgehen musst.
                </p>
                <h3>1. Schritt: Berechne deine monatlichen Einnahmen</h3>
                <p>Zuerst addierst du sämtliche monatliche Einkünfte wie</p>
                <ul>
                    <li>dein Nettoeinkommen (Einkommen exklusive Steuern und Sozialabgaben)</li>
                    <li>Einkünfte durch Beihilfen (z.B. Kinder-, Familien- oder Studienbeihilfe)</li>
                    <li>etwaige Mieteinnahmen</li>
                    <li>Renten</li>
                </ul>
                <p>
                    Vor allem bei Beihilfen solltest du aufpassen, denn die meisten von ihnen haben ein Ablaufdatum: So
                    kannst du beispielsweise die
                    <a
                        href="https://www.arbeiterkammer.at/beratung/berufundfamilie/BeihilfenundFoerderung/Familienbeihilfe.html"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        Familienbeihilfe
                    </a>{" "}
                    nur bis zum 24., in Ausnahmefällen bis zum 25. Geburtstag deiner Kinder beziehen. Fristen dieser Art
                    solltest du bei der der Planung deiner Finanzierung unbedingt beachten.
                </p>
                <h3>2. Schritt: Berechne deine regelmäßigen Ausgaben</h3>
                <p>
                    Du hast die Summe deiner monatlichen Einkünfte berechnet? Sehr gut! Nun folgt der zweite Schritt:
                    Die Addition deiner regelmäßigen Ausgaben. Diese werden als Fix- bzw. Lebenserhaltungskosten
                    bezeichnet und beinhalten:
                </p>
                <ul>
                    <li>Miete sowie Betriebs- und Energiekosten</li>
                    <li>Kosten für Telefon, Internet und Rundfunk</li>
                    <li>Mobilitätskosten (Kosten für eigene Fahrzeuge und/oder öffentliche Verkehrsmittel)</li>
                    <li>Versicherungen</li>
                    <li>Abonnements von Zeitschriften, Streamingdiensten etc.</li>
                    <li>Unterhaltszahlungen, Verpflegung und Kleidung</li>
                    <li>und auch alle Ausgaben für deine Hobbies</li>
                </ul>
                <h3>3. Schritt: Noch eine Subtraktion</h3>
                <p>
                    Hast du erst einmal deine regelmäßigen Einnahmen und Ausgaben berechnet, dann trennt dich nur noch
                    eine einzige Subtraktion von der Bestimmung deines frei verfügbaren Einkommens: Ziehe einfach die
                    Summe deiner monatlichen Ausgaben von der Summe deiner monatlichen Einnahmen ab. Herzlichen
                    Glückwunsch, du hast soeben dein frei verfügbares Einkommen berechnet!
                </p>
                <hr />

                <h2>Nach der Haushaltsrechnung: Die Kreditrate</h2>
                <p>
                    Hast du die Haushaltsrechnung durchgeführt und weißt, wie viel von deinem Einkommen dir monatlich
                    tatsächlich zur Verfügung steht, dann kannst du auch abschätzen, wie hoch deine zukünftige
                    Kreditrate idealerweise sein sollte. Selbstverständlich solltest du nicht den gesamten verfügbaren
                    Teil deines Einkommens für die Erstattung der Raten aufwenden: Plane in jedem Fall Reserven für
                    unerwartete Ausgaben wie Neuanschaffungen oder Reparaturen ein! Auch wenn du dich für einen Kredit
                    mit variablem{" "}
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        Zinssatz
                    </Link>{" "}
                    entscheidest, ist es wichtig, dass du auf potenziell höhere Zinsen vorbereitet bist und diese im
                    Fall der Fälle begleichen kannst.
                </p>
                <p>
                    Hast du (unter Berücksichtigung von Reserven für die genannten Fälle) erst einmal eine Wunschrate
                    festgelegt, so kannst du einen{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>{" "}
                    heranziehen, um die voraussichtliche Laufzeit deines Kredits zu bestimmen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Jetzt Kreditvergleich anfordern!
                    </a>
                </p>
                <hr />

                <h2>Protipp: Förderangebote nutzen!</h2>
                <p>
                    Für die Finanzierung und Sanierung von Immobilien kannst du in Österreich verschiedene Förderungen
                    in Anspruch nehmen. Informiere dich am besten auf der website deines Bundeslandes über das lokale
                    Angebot und profitiere bei deinem Bauprojekt von staatlichen Zuschüssen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Zum Kontaktformular
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"bauen"}></BreadcrumbList>
            <ArticleStructuredData page={"bauen"} heading={"Bauen – Ein Überblick"} />
        </Layout>
    );
};

export default Bauen;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.bauen", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
